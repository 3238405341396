export const baseurl = process.env.REACT_APP_BASE_URL;
export const baseurlSocket = process.env.REACT_APP_SOCKET_URL;
export const socketUrl = process.env.REACT_APP_SOCKET_URL_BLACK_JACK;

// export const baseurl = "http://172.16.6.118:2042"; // my local
// export const baseurlSocket = "ws://172.16.6.118:2008"; // my local
// export const socketUrl = "ws://172.16.6.118:2067"; // local

let url = `${baseurl}/api/v1`;
let lottery = `${url}/lottery`;

const ApiConfig = {
  connectWallet: `${url}/user/connectWallet`,
  getProfile: `${url}/user/getProfile`,
  editProfile: `${url}/user/editProfile`,
  addReffferalCode: `${url}/user/addReffferalCode`,

  //AuthFlow
  login: `${url}/user/login`,
  signup: `${url}/user/signup`,
  forgotPassword: `${url}/user/forgot-password`,
  verifyOtp: `${url}/user/verify-otp`,
  resetPassword: `${url}/user/reset-password`,
  resendOTP: `${url}/user/resendOTP`,

  //Admin Category
  addCategory: `${url}/h2h/addCategory`,
  getCategory: `${url}/h2h/getCategory`,
  deleteCategory: `${url}/h2h/deleteCategory`,
  liveMatchData: `${url}/h2h/liveMatchData`,

  //Admin Fee
  getAdminFee: `${url}/admin/getAdminFee`,
  editAdminFee: `${url}/admin/editAdminFee`,
  topWinner: `${url}/admin/topWinner`,
  popularPlayer: `${url}/admin/popularPlayer`,
  getLotterPort: `${url}/admin/getLotterPort`,

  //Admin Question
  questionLIst: `${url}/h2h/questionLIst`,
  addQuestion: `${url}/h2h/addQuestion`,
  deleteQuestion: `${url}/h2h/deleteQuestion`,
  checkGameStatus: `${url}/h2h/checkGameStatus`,

  ///User h2h
  liveGame: `${url}/h2h/liveGame`,
  betPlace: `${url}/h2h/betPlace`,
  betPlaceV1: `${url}/h2h/betPlaceV1`,
  distributeAmount: `${url}/h2h/distributeAmount`,
  graph_data: `${url}/h2h/graphData`,

  //LOTTERY//
  createRoom: `${lottery}/createRoom`,
  buyTickets: `${lottery}/buyTickets`,
  onGoingEvent: `${lottery}/onGoingEvent`,
  createLottory: `${lottery}/createLottory`,
  listLottory: `${lottery}/listLottory`,
  winningAmount: `${lottery}/winningAmount`,
  amountClaim: `${lottery}/amountClaim`,
  betPlace: `${lottery}/betPlace`,
  getPlaceLottery: `${lottery}/getPlaceLottery`,
  // getLotteryId: `${url}/getLotteryId`,
  getLotteryHistory: `${lottery}/getLotteryHistory`,
  getwinningAmount: `${lottery}/getwinningAmount`,

  //referral
  directIndirectUserList: `${url}/user/directIndirectUserList`,

  //derby game
  scheduleEvent: `${url}/derby/scheduleEvent`,
  derbyhistory: `${url}/derby/history`,
  eventLists: `${url}/derby/eventLists`,
  joinEventV2: `${url}/derby/joinEventV2`,
  leaderboards: `${url}/derby/leaderboards`,
  winnerHorse: `${url}/derby/winnerHorse`,

  // Admin Static Content
  listStaticContent: `${url}/statics/get-all`,
  editStaticContent: `${url}/statics/update`,
  getAllFaqCategory: `${url}/statics/getAllFaqCategory`,
  getAllFaqWithCategory: `${url}/statics/getAllFaqWithCategory`,

  contactUs: `${url}/contact/contactUs`,

  // Admin Faq Management
  addFaq: `${url}/statics/addFaq`,
  editFaq: `${url}/statics/editFaq`,
  deleteFaq: `${url}/statics/deleteFaq`,
  listFAQ: `${url}/statics/listFAQ`,
  getAllFaq: `${url}/statics/getAllFaq`,

  addGameRemainder: `${url}/statics/addGameRemainder`,
  editGameRemainder: `${url}/statics/editGameRemainder`,
  getAllGameRemainder: `${url}/statics/getAllGameRemainder`,
  getAllGameRemainderWithPaginate: `${url}/statics/getAllGameRemainderWithPaginate`,
  viewGameRemainder: `${url}/statics/viewGameRemainder`,
  deleteGameRemainder: `${url}/statics/deleteGameRemainder`,

  ///marketGraph///
  marketPriceHistoricalData: `${url}/h2h/marketPriceHistoricalData`,

  ///New apis///
  viewProfile: `${url}/user/view-profile`,
  viewWallet: `${url}/user/view-wallet`,
  editProfileApi: `${url}/user/edit-profile`,
  socialLogin: `${url}/user/socialLogin`,
  getCoin: `${url}/user/getCoin`,
  depositMoney: `${url}/user/deposite-money`,
  withdrawamount: `${url}/user/withdrawamount`,
  transactionHistory: `${url}/user/transactionHistory`,
  getUsername: `${url}/user/getUsername`,
  refferalHistory: `${url}/user/refferalHistory`,
  getDepositBalence: `${url}/user/getDepositBalence`,
  getMinimumDeposite: `${url}/user/getMinimumDeposite`,
  viewTransactons: `${url}/user/viewTransactons`,
  verifyWithdrawRequest: `${url}/user/verifyWithdrawRequest`,
  leaderboardList: `${url}/user/leaderboardList`,

  ////Admin////
  walletList: `${url}/admin/walletList`,
  withdrwaList: `${url}/admin/withdrwaList`,
  listUser: `${url}/admin/list-user`,
  approveRejectWithdraw: `${url}/admin/approveRejectWithdraw`,
  updateLotteryPort: `${url}/admin/updateLotteryPort`,
  getLotterPort: `${url}/admin/getLotterPort`,

  ///h2hAdmin////
  MatchList: `${url}/h2h/MatchList`,
  listPlayer: `${url}/h2h/listPlayer`,
  createQuestionOfCricket: `${url}/h2h/createQuestionOfCricket`,
  manualqustionList: `${url}/h2h/manualqustionList`,

  // blackjack
  betAmountList: `${url}/blackjack/betAmountList`,

  // lottery
  getLottery: `${url}/lottery/getLottery`,
  userBuyLotteryTicket: `${url}/lottery/userBuyLotteryTicket`,
  getUserBuyPlan: `${url}/lottery/getUserBuyPlan`,
  adminUpdateLottery: `${url}/lottery/adminUpdateLottery`,
  viewLottery: `${url}/lottery/viewLottery`,
  getPotAmount: `${url}/lottery/getPotAmount`,
  // getLotteryId: `${url}/lottery/getLotteryId`,
  // getAllFaqCategory: `${statics}/getAllFaqCategory`,

  ///////Election-game////
  addElectionCategory: `${url}/admin/addElectionCategory`,
  electionCateory: `${url}/admin/electionCateory`,
  deleteElectionCategory: `${url}/admin/deleteElectionCategory`,
  updateElectionCategory: `${url}/admin/updateElectionCategory`,
  ///////////admin-subCategory////
  electionSubCategory: `${url}/admin/electionSubCategory`,
  addElectionSubCategory: `${url}/admin/addElectionSubCategory`,
  updateElectionSubCategory: `${url}/admin/updateElectionSubCategory`,
  deleteElectionSubCategory: `${url}/admin/deleteElectionSubCategory`,
  checkTwitterHandlerName: `${url}/admin/checkTwitterHandlerName`,

  ///////admin-Election-Question/////
  addElectionQA: `${url}/admin/addElectionQA`,
  updateElectionQA: `${url}/admin/updateElectionQA`,
  listElectionQA: `${url}/admin/listElectionQA`,
  deleteElectionQA: `${url}/admin/deleteElectionQA`,
  expireElectionQA: `${url}/admin/expireElectionQA`,
  checkQuizStatus: `${url}/admin/checkQuizStatus`,
  declareElectionResult: `${url}/admin/declareElectionResult`,

  ///////user-Election-mgmt/////
  electionCategory: `${url}/user/electionCategory`,
  electionSubCategory: `${url}/user/electionSubCategory`,
  betOnElection: `${url}/user/betOnElection`,

  ///////////moonpayment//////////
  getMoonPayFee: `${url}/user/getMoonPayFee`,
  getTranskFee: `${url}/user/getTranskFee`,
};
export default ApiConfig;
